.about {
  &-background {
    background-color: hsl(0, 57%, 91%);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-headshot {
    width: 396px;
    height: 476px;
  }

  &-intro {
    text-align: justify;
    color: #756c75;
    > h1 {
      font-style: italic;
      font-weight: bold;
      font-size: 30px;
      line-height: 42px;
    }
    > p {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
    }
  }
}