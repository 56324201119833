.header {
  &-logo {
    font-style:italic;
    font-weight: bold;
    font-size: 30px;
    line-height: 42px;
    color: #756c75 !important;
  }

  &-link {
    font-style:italic;
    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
  }
}